import React from 'react'
import _debounce from 'lodash.debounce'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { scrollToId, EMAIL } from '../../utils'
import { navigate } from 'gatsby'

import Logo from '../../assets/images/logo.inline'

import './header.scss'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.menu = React.createRef();
    this.openMenu = this.openMenu.bind(this)
    this.state = {
      scrollClass: '',
      menuIsOpen: false
    }
  }

  detectMenu = () => {
    let scrollDistance = window.pageYOffset + 90;
    let menuLinks = this.menu?.current?.childNodes;

    window.history.replaceState(null, null, ' ');
    menuLinks && menuLinks.forEach((item, index) => {
      let sectionId = item.childNodes[0].getAttribute('data-section')
      let section = document.getElementById(sectionId)
      item.classList.remove('is-active');
      if (section) {
        let top = section.offsetTop - 60
        let bottom = top + section.offsetHeight;

        if (scrollDistance >= top && scrollDistance <= bottom) {
          item.classList.add('is-active')
          if (window.history.pushState) {
            window.history.pushState(null, null, `#${sectionId}`)
          } else {
            window.location.hash = sectionId
          }
          return
        }
      }
    })
  }
  openMenu() {
    this.setState(prevState => ({
      menuIsOpen: !prevState.menuIsOpen,
    }))
  }
  goToSection = (event) => {
    this.openMenu()
    let section = event.target.getAttribute('data-section')
    if (document.getElementById(section)) {
      scrollToId(document.getElementById(section), 300, 'linear')
    } else {
      navigate(`/#${section}`)
    }
  }
  scrollHandler = () => {
    window.addEventListener('scroll', event => {
      this.setState({
        scrollClass: window.pageYOffset >= 30 ? 'is-sticky' : ''
      })
    })
    window.addEventListener('scroll', _debounce(() => {
      this.detectMenu()
      }, 50)
    )
  }

  componentDidMount() {
    this.scrollHandler()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  render() {
      const { menuIsOpen, scrollClass } = this.state;
      return (
        <header id="header"
            className={`header ${scrollClass} ${menuIsOpen ? 'burger-show' : ''}`}>
            <div className="container">
                <AniLink cover direction="right" bg="#00EBAA" to="/" title="Back to home">
   
                </AniLink>

                <button type="button"
                    className={menuIsOpen ? 'burger is-active' : 'burger'}
                    onClick={this.openMenu}
                    aria-label="Expand menu">
                    <i />
                </button>

                <nav className="header__nav">
                    {/**<ul className="header__nav-list" ref={this.menu}>
                        <li>
                            <button type="button" data-section='about' onClick={this.goToSection}>About</button>
                        </li>
                        <li>
                          <button type="button" data-section='services' onClick={this.goToSection}>Services</button>
                        </li>
                        <li>
                          <button type="button" data-section='technologies' onClick={this.goToSection}>Technologies</button>
                        </li>
                        <li>
                          <button type="button" data-section='process' onClick={this.goToSection}>Process</button>
                        </li>
      </ul>**/}


                    <a
                        href={"https://www.notion.so/Black-High-Tech-Innovators-and-Entrepreneurs-a30cd054ecc547f0bebf31b0e31deb03#aa599d0b0a2448c29c5a46ea65d4d2b6"}
                        className="g-button g-button--small g-button--outlined"
                        title="Hire us"
                    >
                        <span>Projects</span>
                    </a>
                </nav>
            </div>
        </header>
    )
  }
}

export default Header
