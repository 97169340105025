import React, {useState} from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './get-in-touch.scss';
import {callService} from "../../utils/firebaseUtil";
import {validateEmail} from "../../utils";

const GetInTouch = () => {
  const [isProcessing, setProcessing] = useState(false)
  const [email, setEmail] = useState('')
  const submit = async () => {
    const ReSwal = withReactContent(Swal)
    if(!validateEmail(email)) {
      return await ReSwal.fire({
        title: 'Error!',
        text: 'Email is invalid',
        icon: 'error',
        confirmButtonText: 'close',
        confirmButtonColor: '#f27474',
        showCloseButton: true,
      })
    }
    try {
      setProcessing(true)
      await callService('addToWhiteList', { email });
      setProcessing(false)
      setEmail('')
      return await ReSwal.fire({
        title: 'Success',
        text: `You've been added to waitlist successfully.`,
        icon: 'success',
        confirmButtonText: 'close',
        showCloseButton: true,
      })
    }catch (e){
      setProcessing(false)
      return await ReSwal.fire({
        title: 'Error!',
        text: `You've already been added to waitlist.`,
        icon: 'error',
        confirmButtonText: 'close',
        confirmButtonColor: '#f27474',
        showCloseButton: true,
      })
    }
  }

  return (
    <section className="touch">
      <div className="container">
        <div className="circle touch__circle-1" data-aos="zoom-in"/>
        <div className="touch__circle-2" data-aos="zoom-in"/>
        <div className="col-xs-12 col-md-6 col-md-offset-2">
          <div className="circle touch__circle"/>
          <h4 className="g-h4" data-aos=""><p><span>Get in touch</span></p></h4>
        </div>
        <div className="col-xs-8 col-md-6 col-md-offset-2">
     <h1 style={{color:"grey"}}>ben@black-hightech.com</h1>
        </div>
    
      </div>
    </section>
  )
};

export default GetInTouch;
